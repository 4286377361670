<template>
    <div class="LayoutNext">
        <div v-if="isMobile && isExpanded" class="overlay" @click="toggleSideBar"></div>
        <MDBNavbar id="mainNavbar" class="p-2 px-4 sticky-top" style="padding-left: 11.6px; z-index: 100;">
            <MDBBtn :class="isMobile ? 'd-block ms-1' : 'd-none'" color="tertiary" aria-controls="#mainSidenav"
                @click="toggleSideBar" id="mainSidenavTogglerNavbar" style="padding-left: 0;">
                <vue-feather type="menu" :stroke-width="2.5" style="height: 1rem;margin-top: 4px" />
            </MDBBtn>
            <h5
                :class="isExpanded ? 'page-title-expanded my-auto text-black d-none d-md-block' : 'page-title-slim my-auto text-black d-none d-md-block'">
                <slot name="page-title"></slot>
            </h5>
            <MDBNavbarNav right>
                <MDBNavbarItem>
                    <MDBDropdown btnGroup align="lg-end" v-model="userDropdown" id="userDropdown">
                        <MDBDropdownToggle tag="a" class="nav-link d-flex align-items-center"
                            @click="userDropdown = !userDropdown" color="dark">
                            <div class="default-avatar shadow-lg me-1" v-if="!userDetail.profilePictureUrl">
                                <span class="avatar-letter">{{ avatarLetter }}</span>
                            </div>
                            <img v-else :src="avatarImage" alt="Profile Picture"
                                class="profile-avatar shadow-lg me-1" />
                            <div class="d-flex flex-column align-items-center mx-2 justify-content-start">
                                <div class="text-capitalize">
                                    {{ userDetail.firstName }}
                                    {{ userDetail.lastName }}
                                </div>
                                <span class="w-100 user-type"
                                    style="text-transform: uppercase; font-size: 11px; font-weight: bold;"
                                    :style="{ color: setUserTypeColor(userDetail.userTypeId) }">
                                    {{ setUserType(userDetail.userTypeId) }}
                                </span>
                            </div>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu>
                            <MDBDropdownItem class="nav-link-item d-flex align-items-center" href="#/user-profile">
                                <vue-feather type="user" :stroke-width="2.5" size="18px" class="me-2" />
                                <span>User Profile</span>
                            </MDBDropdownItem>
                            <MDBDropdownItem class="nav-link-item d-flex align-items-center" href="#"
                                @click="handleLogout">
                                <vue-feather type="log-out" :stroke-width="2.5" size="18px" class="me-2" />
                                <span>Logout</span>
                            </MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                </MDBNavbarItem>
            </MDBNavbarNav>
        </MDBNavbar>
        <div class="layout-wrapper">
            <div :class="['sidenav', 'd-flex', 'flex-column', isSlim ? 'slim' : '', isExpanded ? 'expanded' : '']"
                id="sidenav">
                <div class="d-flex align-items-center sticky-top bg-white">
                    <MDBBtn :class="isMobile ? 'd-block ms-4' : 'd-none'" color="tertiary" aria-controls="#mainSidenav"
                        @click="toggleSideBar" id="mainSidenavTogglerNavbar" style="padding-left: 0;">
                        <vue-feather type="menu" :stroke-width="2.5" style="height: 1rem;margin-top: 4px" />
                    </MDBBtn>
                    <div v-if="isExpanded"
                        :class="isMobile ? 'd-flex justify-content-start ps-1 py-2' : 'd-flex justify-content-center p-2'">
                        <img @click="toDashboard" :src="expandedLogo" alt="creator-shield-logo"
                            class="img-fluid object-fit-contain sidenav-non-slim sidenav-mobile-logo" />
                    </div>
                    <div v-else class="d-flex justify-content-center p-2">
                        <img @click="toDashboard" :src="slimLogo" alt="creator-shield-logo"
                            class="img-fluid object-fit-contain" style="width: 91%;" />
                    </div>
                </div>
                <hr class="m-0" />
                <router-link to="/dashboard" active-class="bg-primary text-white" class="d-flex align-items-center">
                    <vue-feather type="home" :stroke-width="2.5" class="sidenav-feather-icon" />
                    <span v-if="isExpanded" class="navlink-text">Home</span>
                </router-link>
                <router-link to="/assets" active-class="bg-primary text-white" class="d-flex align-items-center">
                    <vue-feather type="film" :stroke-width="2.5" class="sidenav-feather-icon" />
                    <span v-if="isExpanded" class="navlink-text">Assets</span>
                </router-link>
                <router-link to="/asset-labels" active-class="bg-primary text-white" class="d-flex align-items-center">
                    <vue-feather type="folder" :stroke-width="2.5" class="sidenav-feather-icon" />
                    <span v-if="isExpanded" class="navlink-text">Asset Labels</span>
                </router-link>
                <template v-if="isAdmin || isSuperAdmin">
                    <div :class="['d-flex align-items-center', { 'expanded': isExpanded, 'active-page': isActivePex }, hideSidenav ? 'sidenav-item-collapse' : 'category']"
                        @click="toggleCategory('pexSubMenu')">
                        <vue-feather v-if="hideSidenav" type="eye-off" :stroke-width="2.5"
                            class="sidenav-overlay-icon" />
                        <vue-feather type="bar-chart-2" :stroke-width="2.5"
                            style="transform: rotate(90deg);height: 1.1rem;margin-left: 4.3px;" />
                        <span v-if="isExpanded" class="navlink-text">Pex</span>
                        <vue-feather v-if="isExpanded && !isPexSubMenuVisible" type="chevron-down"
                            style="height:1.1rem;margin-left: auto;" />
                        <vue-feather v-if="isExpanded && isPexSubMenuVisible" type="chevron-up"
                            style="height:1.1rem;margin-left: auto;" />
                    </div>
                    <div v-show="isExpanded && isPexSubMenuVisible" class="sub-menu">
                        <router-link active-class="bg-primary text-white" to="/pex-collections"
                            class="d-flex align-items-center py-2" :class="hideSidenav ? 'sidenav-item' : ''">
                            <vue-feather v-if="hideSidenav" type="eye-off" :stroke-width="2.5"
                                class="sidenav-overlay-icon" />
                            <vue-feather type="folder" :stroke-width="2.5" style="height: 1.1rem;margin-left: -5px;" />
                            <span v-if="isExpanded" class="navlink-text">Pex Collections</span>
                        </router-link>
                        <router-link active-class="bg-primary text-white" to="/pex-allowlist"
                            class="d-flex align-items-center py-2" :class="hideSidenav ? 'sidenav-item' : ''">
                            <vue-feather v-if="hideSidenav" type="eye-off" :stroke-width="2.5"
                                class="sidenav-overlay-icon" />
                            <vue-feather type="user-check" :stroke-width="2.5"
                                style="height: 1.1rem;margin-left: -5px;" />
                            <span v-if="isExpanded" class="navlink-text">Pex Allowlist</span>
                        </router-link>
                        <router-link @mouseover="hovered = true" @mouseleave="hovered = false"
                            active-class="bg-primary text-white" to="/pex-copies" class="d-flex align-items-center py-2"
                            style="padding-left: 12px;" :class="hideSidenav ? 'sidenav-item' : ''">
                            <vue-feather v-if="hideSidenav" type="eye-off" :stroke-width="2.5"
                                class="sidenav-overlay-icon" />
                            <img :class="invertClass" src="@/assets/copyright-white.png" alt="pex-copies-logo"
                                style="margin-left: 2px;">
                            <span v-if="isExpanded" class="navlink-text">Pex Copies</span>
                        </router-link>
                    </div>
                    <div :class="['d-flex align-items-center', { 'expanded': isExpanded, 'active-page': isActiveDailySync }, hideSidenav ? 'sidenav-item-collapse' : 'category']"
                        @click="toggleCategory('dailySyncSubMenu')">
                        <vue-feather v-if="hideSidenav" type="eye-off" :stroke-width="2.5"
                            class="sidenav-overlay-icon" />
                        <vue-feather type="shield" :stroke-width="2.5" class="sidenav-feather-icon" />
                        <span v-if="isExpanded" class="navlink-text">Sync Management</span>
                        <vue-feather v-if="isExpanded && !isDailySyncSubMenuVisible" type="chevron-down"
                            style="height:1.1rem;margin-left: auto;" />
                        <vue-feather v-if="isExpanded && isDailySyncSubMenuVisible" type="chevron-up"
                            style="height:1.1rem;margin-left: auto;" />
                    </div>
                    <div v-if="isExpanded && isDailySyncSubMenuVisible" class="sub-menu">
                        <router-link active-class="bg-primary text-white" to="/daily-sync"
                            class="d-flex align-items-center py-2" :class="hideSidenav ? 'sidenav-item' : ''">
                            <vue-feather v-if="hideSidenav" type="eye-off" :stroke-width="2.5"
                                class="sidenav-overlay-icon" />
                            <vue-feather type="refresh-cw" :stroke-width="2.5" class="sub-sidenav-feather-icon" />
                            <span v-if="isExpanded" class="navlink-text">Daily Sync</span>
                        </router-link>
                        <router-link active-class="bg-primary text-white" to="/new-sync"
                            class="d-flex align-items-center py-2" :class="hideSidenav ? 'sidenav-item' : ''">
                            <vue-feather v-if="hideSidenav" type="eye-off" :stroke-width="2.5"
                                class="sidenav-overlay-icon" />
                            <vue-feather type="bell" :stroke-width="2.5" class="sub-sidenav-feather-icon" />
                            <span v-if="isExpanded" class="navlink-text">New Sync</span>
                        </router-link>
                    </div>
                    <router-link to="/users" active-class="bg-primary text-white" class="d-flex align-items-center"
                        :class="hideSidenav ? 'sidenav-item' : ''">
                        <vue-feather v-if="hideSidenav" type="eye-off" :stroke-width="2.5"
                            class="sidenav-overlay-icon" />
                        <vue-feather type="users" :stroke-width="2.5" class="sidenav-feather-icon" />
                        <span v-if="isExpanded" class="navlink-text">Users</span>
                    </router-link>
                    <div :class="['d-flex align-items-center', { 'expanded': isExpanded, 'active-page': isActiveReports }, hideSidenav ? 'sidenav-item-collapse' : 'category']"
                        @click="toggleCategory('reportsSubMenu')">
                        <vue-feather v-if="hideSidenav" type="eye-off" :stroke-width="2.5"
                            class="sidenav-overlay-icon" />
                        <vue-feather type="file" :stroke-width="2.5" class="sidenav-feather-icon" />
                        <span v-if="isExpanded" class="navlink-text">Reports</span>
                        <vue-feather v-if="isExpanded && !isReportsSubMenuVisible" type="chevron-down"
                            style="height:1.1rem;margin-left: auto;" />
                        <vue-feather v-if="isExpanded && isReportsSubMenuVisible" type="chevron-up"
                            style="height:1.1rem;margin-left: auto;" />
                    </div>
                    <div v-if="isExpanded && isReportsSubMenuVisible" class="sub-menu">
                        <router-link active-class="bg-primary text-white" to="/upload-earnings-report"
                            class="d-flex align-items-center py-2" :class="hideSidenav ? 'sidenav-item' : ''">
                            <vue-feather v-if="hideSidenav" type="eye-off" :stroke-width="2.5"
                                class="sidenav-overlay-icon" />
                            <vue-feather type="file-text" :stroke-width="2.5" class="sub-sidenav-feather-icon" />
                            <span v-if="isExpanded" class="navlink-text">Upload Earnings Report</span>
                        </router-link>
                        <router-link active-class="bg-primary text-white" to="/daily-sync-report"
                            class="d-flex align-items-center py-2" :class="hideSidenav ? 'sidenav-item' : ''">
                            <vue-feather v-if="hideSidenav" type="eye-off" :stroke-width="2.5"
                                class="sidenav-overlay-icon" />
                            <vue-feather type="file" :stroke-width="2.5" class="sub-sidenav-feather-icon" />
                            <span v-if="isExpanded" class="navlink-text">Daily Sync Report</span>
                        </router-link>
                    </div>
                </template>
                <div class="mt-auto" style="border-top: 1px solid #dfdfdf;">
                    <div :class="{ 'mt-auto': userDetail.userTypeId === 2 }">
                        <div :class="isExpanded ? 'd-flex justify-content-between align-items-center' : 'd-block'">
                            <a href="https://www.creatorshield.com/support" target="_blank"
                                class="category d-flex align-items-center">
                                <vue-feather type="help-circle" :stroke-width="2.5" class="sidenav-feather-icon" />
                                <span v-if="isExpanded" class="navlink-text text-nowrap">Help Center</span>
                            </a>
                            <button v-if="isExpanded"
                                class="category d-flex justify-content-center align-items-center w-25"
                                @click="toggleSideBar">
                                <vue-feather type="chevrons-left"
                                    style="height:1.1rem;margin: 2.4px 0;margin-right: -2px;" />
                            </button>
                            <button v-else class="category d-flex justify-content-center align-items-center"
                                @click="toggleSideBar">
                                <vue-feather type="chevrons-right" style="height:1.1rem;margin-left: -2px;" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div :id="isMobile ? 'content-mobile' : (isExpanded ? 'content-expanded' : 'content-slim')">
                <MDBAlert v-if="hideSidenav" class="impersonate-banner" static>
                    <i class="fas fa-user-secret me-3"></i>
                    <p>
                        VIEWING as
                        <strong>{{ lastName }}, {{ firstName }}</strong> [IMPERSONATING]
                    </p>
                    <MDBTooltip v-model="exitUserManagement">
                        <template #reference>
                            <i class="fas fa-backspace" @click="exitImpersonation"></i>
                        </template>
                        <template #tip> Exit Impersonation </template>
                    </MDBTooltip>
                </MDBAlert>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import { MDBNavbar, MDBBtn, MDBNavbarNav, MDBNavbarItem, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBAlert, MDBTooltip } from "mdb-vue-ui-kit";
import { computed, onMounted, ref, watch, watchEffect, onBeforeUnmount } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useUserDetailStore } from "../../store/userDetailStore";
import { GetCurrent } from "../../services/Users/GetCurrent";
import { Logout } from "../../services/Logout/Logout";
import { storeToRefs } from "pinia";
import { useImpersonateStore } from "../../store/impersonateStore";
import { useTimeout } from "@vueuse/core";
import VueFeather from 'vue-feather';

const getSidebarStateFromLocalStorage = () => {
    const storedState = localStorage.getItem('sidebarState');
    if (storedState) {
        return JSON.parse(storedState);
    }
    return { isSlim: true, isExpanded: false };
};

const storedSidebarState = getSidebarStateFromLocalStorage();

const isSlim = ref(storedSidebarState.isSlim);
const isExpanded = ref(storedSidebarState.isExpanded);

const saveSidebarState = () => {
    const sidebarState = {
        isSlim: isSlim.value,
        isExpanded: isExpanded.value,
    };
    localStorage.setItem('sidebarState', JSON.stringify(sidebarState));
};

watch([isSlim, isExpanded], saveSidebarState);

onMounted(() => {
    updateResolution();
    window.addEventListener('resize', updateResolution);

    if (!isMobile.value) {
        const storedSidebarState = localStorage.getItem('sidebarState');
        if (storedSidebarState) {
            const parsedState = JSON.parse(storedSidebarState);
            isSlim.value = parsedState.isSlim !== undefined ? parsedState.isSlim : true;
            isExpanded.value = parsedState.isExpanded !== undefined ? parsedState.isExpanded : false;
        }
    } else {
        isSlim.value = true;
        isExpanded.value = false;
    }
});

const isPexSubMenuVisible = ref(false);
const isDailySyncSubMenuVisible = ref(false);
const isReportsSubMenuVisible = ref(false);

const toggleCategory = (category) => {
    if (category === 'pexSubMenu') {
        if (!isExpanded.value) {
            isExpanded.value = true;
        }
        isPexSubMenuVisible.value = !isPexSubMenuVisible.value;
    } else if (category === 'dailySyncSubMenu') {
        if (!isExpanded.value) {
            isExpanded.value = true;
        }
        isDailySyncSubMenuVisible.value = !isDailySyncSubMenuVisible.value;
    } else if (category === 'reportsSubMenu') {
        if (!isExpanded.value) {
            isExpanded.value = true;
        }
        isReportsSubMenuVisible.value = !isReportsSubMenuVisible.value;
    }
};

const toggleSideBar = () => {
    isExpanded.value = !isExpanded.value;
    isSlim.value = !isExpanded.value;
};

watch([isSlim, isExpanded], () => {
    if (!isMobile.value) {
        const sidebarState = {
            isSlim: isSlim.value,
            isExpanded: isExpanded.value,
        };
        localStorage.setItem('sidebarState', JSON.stringify(sidebarState));
    }
});

watch(isExpanded, (newVal) => {
    if (newVal) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
});

const isMobile = ref(window.innerWidth <= 767);

const updateResolution = () => {
    isMobile.value = window.innerWidth <= 767;
};

watchEffect(() => {
    if (isMobile.value) {
        isExpanded.value = false;
    }
});

onMounted(() => {
    window.addEventListener('resize', updateResolution);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', updateResolution);
});

const router = useRouter();
const toDashboard = () => {
    router.push("/dashboard");
};

const adjustmentsURL = ref("");
const userDropdown = ref(false);

const userDetailStore = useUserDetailStore();
const { userDetail, isAdmin, isSuperAdmin } = storeToRefs(userDetailStore);
onMounted(async () => {
    const response = await GetCurrent();
    userDetail.value = response;
    adjustmentsURL.value = `/users/${response.id}/adjustments`;
});

const handleLogout = () => {
    Logout().then(() => {
        router.push("/login");
    });
    window.dispatchEvent(new Event('reset-alerts'));
};

const exitUserManagement = ref(false);
const { hideSidenav, exitImpersonation, firstName, lastName } =
    useImpersonate();
function useImpersonate() {
    const impersonateStore = useImpersonateStore();
    const { isImpersonating, userImpersonateDetail } =
        storeToRefs(impersonateStore);
    const { firstName, lastName } = userImpersonateDetail.value;
    const hideSidenav = ref(false);
    const { start, ready } = useTimeout(3000, { controls: true });
    onMounted(async () => {
        if (isImpersonating.value) {
            adjustmentsURL.value = `/users/${userImpersonateDetail.value.id}/adjustments`;
            start();
        }
    });

    watch(ready, (newVal) => {
        hideSidenav.value = newVal && isImpersonating.value;
    });

    const exitImpersonation = () => {
        isImpersonating.value = false;
        router.push({ name: "UserManagement" });
    };

    return {
        hideSidenav,
        exitImpersonation,
        firstName,
        lastName,
    };
}

const setUserType = (userTypeId) => {
    switch (userTypeId) {
        case 1:
            return "Admin";
        case 2:
            return "User";
        case 3:
            return "Distributor";
        case 4:
            return "Super Admin";
        default:
            return "-";
    }
}

const setUserTypeColor = (userTypeId) => {
    switch (userTypeId) {
        case 1:
            return "var(--blue)";
        case 4:
            return "var(--primary)";
        case 2:
        case 3:
        default:
            return "var(--accent)";
    }
}

const avatarLetter = computed(() => {
    return userDetail.value?.firstName?.charAt(0).toUpperCase() || '';
});

const route = useRoute();

const isPexActive = () => {
    const categoryRoutes = ['/pex-collections', '/pex-allowlist', '/pex-copies'];
    return categoryRoutes.includes(route.path);
};

watch(() => route.path, (newPath) => {
    const pexRoutes = ['/pex-collections', '/pex-allowlist', '/pex-copies'];
    if (pexRoutes.some(route => newPath.includes(route))) {
        isPexSubMenuVisible.value = true;
    } else {
        isPexSubMenuVisible.value = false;
    }
},
    { immediate: true });

const isDailySyncActive = () => {
    const categoryRoutes = ['/daily-sync', '/new-sync'];
    return categoryRoutes.includes(route.path);
};

watch(() => route.path, (newPath) => {
    const dailySyncRoutes = ['/daily-sync', '/new-sync'];
    if (dailySyncRoutes.includes(newPath)) {
        isDailySyncSubMenuVisible.value = true;
    } else {
        isDailySyncSubMenuVisible.value = false;
    }
}, { immediate: true });

const isReportsActive = () => {
    const categoryRoutes = ['/upload-earnings-report', '/daily-sync-report'];
    return categoryRoutes.includes(route.path);
};

watch(() => route.path, (newPath) => {
    const reportRoutes = ['/upload-earnings-report', '/daily-sync-report'];
    if (reportRoutes.includes(newPath)) {
        isReportsSubMenuVisible.value = true;
    } else {
        isReportsSubMenuVisible.value = false;
    }
}, { immediate: true });

const expandedLogo = ref(require('@/assets/cs-logo.png'));
const slimLogo = ref(require('@/assets/cs-logo-new-slim.png'));

const hovered = ref(false);
const invertClass = ref("");

const isPexCopyActive = () => {
    const pexCopyRoute = '/pex-copies';
    return pexCopyRoute.includes(route.path);
};

watchEffect(() => {
    if (hovered.value || isPexCopyActive()) {
        invertClass.value = "pexCopiesLogo";
    } else {
        invertClass.value = "invertedPexCopiesLogo";
    }
});

const avatarImage = ref(userDetail.value.profilePictureUrl);
watch(() => userDetail.value.profilePictureUrl, (newUrl, oldUrl) => {
    if (newUrl !== oldUrl) {
        avatarImage.value = newUrl;
    }
});

const isActivePex = computed(() => {
    if (isPexActive()) {
        if (isExpanded.value) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
});

const isActiveDailySync = computed(() => {
    if (isDailySyncActive()) {
        if (isExpanded.value) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
});

const isActiveReports = computed(() => {
    if (isReportsActive()) {
        if (isExpanded.value) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
});

router.beforeEach((to, from, next) => {
    const storedSidebarState = localStorage.getItem('sidebarState');
    if (storedSidebarState) {
        const parsedState = JSON.parse(storedSidebarState);
        isSlim.value = parsedState.isSlim;
        isExpanded.value = parsedState.isExpanded;
    }
    next();
});
</script>

<style>
:root {
    --white: #ffffff;
    --primary: #ff7423;
    --secondary: #e2e6ef;
    --accent: #02061b;
    --blue: #1f87fc;
}

@font-face {
    font-family: 'GilroyRegular';
    src: url('../../assets/Gilroy-Font/Gilroy-Regular.ttf');
}

@font-face {
    font-family: 'GilroyBold';
    src: url('../../assets/Gilroy-Font/Gilroy-Bold.ttf');
}

* {
    font-family: 'GilroyRegular', sans-serif;
}

.page-title-slim,
.page-title-expanded,
th,
.avatar-letter,
.user-type {
    font-family: 'GilroyBold';
}

.sidenav-non-slim,
.sidebarDropdownTitle {
    font-weight: bolder;
}

.sidenav-link:hover,
.sidenav-link:focus,
.sidenav-link.active {
    background-color: var(--primary) !important;
    color: white !important;
}

.tooltip-inner {
    background-color: var(--primary);
    font-size: 12px;
    padding: 3px 12px;
}

.btn-primary {
    background-color: var(--primary);
}
</style>

<style scoped>
.invertedPexCopiesLogo {
    width: 10%;
    filter: invert(1) brightness(2);
}

.pexCopiesLogo {
    width: 10%;
}

.bg-primary {
    background-color: var(--primary) !important;
}

.sidenav {
    height: 100%;
    width: 70px;
    position: fixed;
    left: 0;
    top: 0;
    background-color: white;
    transition: width 0.3s ease;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 1050;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1050;
}

.sidenav.expanded {
    width: 250px;
}

.sidenav a {
    padding: 15px 20px;
    text-decoration: none;
    color: black;
    display: block;
    transition: all 0.3s;
}

.sidenav .category {
    cursor: pointer;
    background-color: white;
    padding: 15px 20px;
    display: block;
    color: black;
    border: none;
    width: 100%;
    text-align: left;
    outline: none;
}

.sidenav .sub-menu {
    padding-left: 25px;
}

.sidenav a:hover,
.sidenav .category:hover {
    background-color: var(--primary);
    color: white;
}

@media (max-width: 767px) {
    .sidenav.slim {
        display: none !important;
    }

    .sidenav.expanded {
        display: flex !important;
    }
}

.LayoutNext {
    background-color: var(--white) !important;
    overflow-y: auto;
}

#mainNavbar {
    background-color: white;
    color: var(--primary);
}

#mainSidenavTogglerNavbar,
#mainSidenavTogglerSidenav {
    font-size: 16px;
    box-shadow: none;
}

:global(#mainSidenav + .sidenav-backdrop) {
    background-color: rgba(0, 0, 0, 0.4);
}

:global(#mainSidenav .sidenav-menu) {
    color: var(--accent);
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

.logo2 {
    width: 40px;
}

.nav-link {
    color: var(--accent);
    padding: 0;
}

:deep(#mainSidenav .nav-link-item:hover) {
    background-color: var(--primary);
    color: white;
    cursor: pointer;
}

.sidenav-link {
    border-radius: 0;
}

.sidenav-link.active {
    background-color: var(--primary);
    color: white;
    cursor: pointer;
}

#userDropdown {
    box-shadow: none;
}

#content-slim {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 90px;
}

#content-expanded {
    margin-left: 250px;
    transition: margin-left 0.3s;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
    padding-top: 20px;
    padding-inline: 20px;
}

#content-mobile {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
    padding-top: 20px;
    padding-inline: 20px;
}

.impersonate-banner {
    display: flex;
    align-items: center;
    color: white;
    background-color: var(--primary);
}

.impersonate-banner p {
    margin-bottom: 0;
    margin-right: auto;
}

.impersonate-banner :deep(span) {
    cursor: pointer;
}

.page-title-slim {
    padding-left: 68px;
}

.page-title-expanded {
    padding-left: 250px;
}

.feather-icon {
    height: 1rem;
}

.sidenav-feather-icon {
    height: 1rem;
    margin-left: 5.8px;
}

.sidenav-item-collapse {
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 15px 20px;
    display: block;
    border: none;
    width: 100%;
    text-align: left;
    outline: none;
    color: black;
}

.sidenav-item {
    position: relative;
    background-color: rgba(0, 0, 0, 0.3);
}

.sidenav-overlay-icon {
    height: 1.4rem;
    position: absolute;
    top: 26%;
    left: 49%;
    transform: translateX(-50%);
    z-index: 1;
}

.sub-sidenav-feather-icon {
    height: 1rem;
    margin-left: -5px;
}

.navlink-text {
    margin-left: 12px;
    font-weight: bold;
    font-size: 14px;
}

.default-avatar {
    width: 40px;
    height: 40px;
    background-color: var(--primary);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 20px;
    font-weight: bold;
}

.profile-avatar {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    object-fit: cover;
}

.avatar-letter {
    text-transform: uppercase;
}

.sidenav-mobile-logo {
    width: 72%;
}

@media screen and (max-width: 767px) {
    .sidenav-mobile-logo {
        width: 90%;
    }

    #content {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 60px);
        padding-top: 20px;
        padding-inline: 20px;
    }
}

.active-page {
    background-color: var(--primary) !important;
    color: white !important;
}

#sidenav::-webkit-scrollbar {
    width: 8px;
    height: 5px;
}

#sidenav::-webkit-scrollbar-thumb {
    background-color: #888;
}

#sidenav::-webkit-scrollbar-track {
    background-color: #eee;
}
</style>
